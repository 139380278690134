import React, {
  useCallback,
  memo,
  useRef,
  useContext,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Accordion,
  Icon,
  Button,
  Textarea,
  ArrowIconRoot,
  Box,
  Loader,
} from "@cyberalarm/ui";
import { toast } from "react-toastify";
import { Icons, useToggle, api } from "@cyberalarm/common";
import build from "redux-object";
import normalize from "json-api-normalizer";
import { SectionLabel } from "../modal-preview-components";
import { useDomainID } from "utils/use-domain-id";
import { domainSelectors, DomainStateContext } from "store";

import { i18n } from "./schema";

import { useMappedTranslation } from "utils/use-mapped-translation";

import { SupportRequestDetails } from "./support-request-details";

interface SupportRequestProps {
  result: CheckResult;
  supportTicket: SupportTicket | undefined;
  setSupportTicket: (ticket: SupportTicket) => void;
}

export const SupportRequest = memo(
  ({ result, supportTicket, setSupportTicket }: SupportRequestProps) => {
    const state = useContext(DomainStateContext);
    const [submitting, setSubmitting] = useState(false);
    const domainID = useDomainID();

    const activeDomain = domainSelectors.getActiveDomain(state, {
      activeDomainID: domainID ?? "",
    });

    const domain = useMemo(
      () =>
        activeDomain &&
        domainID &&
        build({ domain: activeDomain }, "domain", domainID),
      [activeDomain, domainID]
    );

    const inputReference = useRef<null | HTMLTextAreaElement>(null);
    const [isAccordionOpen, toggleAccordion] = useToggle();
    const [buttonEnabled, setButtonEnabled] = useToggle(true);
    const problem = useMappedTranslation(
      result?.checkDescription?.problem,
      result?.variables
    );
    const description = useMappedTranslation(
      result?.checkDescription?.description,
      result?.variables
    );
    const additionalInfo = useMappedTranslation(
      result?.checkDescription?.additionalInfo,
      result?.variables
    );

    const action = useMappedTranslation(
      result?.checkDescription?.action,
      result?.variables
    );

    const createTicket = useCallback(async () => {
      try {
        setSubmitting(true);
        setButtonEnabled(false);
        const data = await api("support/create_ticket", {
          method: "POST",
          body: {
            domain: domain.name,
            check_result_id: result.id,
            check_result_description: `Problem - ${problem} \n Description - ${description} \n Additional Info - ${additionalInfo} \n Action - ${action}`,
            request_description: inputReference.current?.value,
            type: result.status === "warnings" ? "warning" : "alarm",
          },
        });
        const normalizedData = normalize(data);
        const builtTicketata = build(normalizedData, "supportTicket");
        setSubmitting(false);
        setSupportTicket(builtTicketata[0]);
        toast.success("Ticket created successfully");
      } catch (error) {
        setSubmitting(false);
        toast.error("Failed to retrieve support tickets");
      }
    }, [
      result,
      setSupportTicket,
      setButtonEnabled,
      problem,
      description,
      additionalInfo,
      action,
      domain.name,
    ]);

    return (
      <Box>
        <Accordion
          isOpen={isAccordionOpen}
          onClick={toggleAccordion}
          root={
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              marginTop={3}
            >
              <SectionLabel>
                {!supportTicket ? "Request help" : "Support ticket details"}
              </SectionLabel>

              <ArrowIconRoot isOpen={isAccordionOpen}>
                <Icon name={Icons.arrow} />
              </ArrowIconRoot>
            </Box>
          }
        >
          {!supportTicket && !submitting && (
            <Box marginTop={3} flex={1}>
              <Textarea
                valid={true}
                ref={inputReference}
                style={{ fontSize: "16px" }}
              />
              <Button
                onClick={createTicket}
                variant="primary"
                marginTop="20px"
                disabled={!buttonEnabled}
              >
                <FormattedMessage {...i18n.createTicket} />
              </Button>
            </Box>
          )}
          {submitting && <Loader />}
          {supportTicket && !submitting && (
            <Box marginTop={3} flex={1}>
              <SupportRequestDetails supportTicket={supportTicket} />
            </Box>
          )}
        </Accordion>
      </Box>
    );
  }
);

SupportRequest.displayName = "SupportRequest";
