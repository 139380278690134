import { defineMessages } from "react-intl";
import * as yup from "yup";

import { required } from "shared-schema";

export const i18n = defineMessages({
  supportPageTitle: {
    defaultMessage: "Support",
    id: "support.Title",
  },
  save: {
    defaultMessage: "Save",
    id: "team.save",
  },
  reset: {
    defaultMessage: "Reset",
    id: "team.reset",
  },
});

export const newTicketSchema = yup.object().shape({
  requestDescription: yup
    .string()
    .ensure()
    .required(required.id),
});
