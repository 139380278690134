import React, { Fragment, memo, useState, useEffect } from "react";
import { Box, FormTitle, Icon, Text, Modal, TitleBar } from "@cyberalarm/ui";
import { Icons } from "@cyberalarm/common";
import { api } from "@cyberalarm/common";
import build from "redux-object";
import normalize from "json-api-normalizer";
import { _, Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";

import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { CreateTicketModal } from "./create-ticket";
import { getCapitalize } from "@cyberalarm/common";

const StyledLinkText = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  color: ${themeGet("colors.black")};
  margin-left: 10px;
  :hover {
    text-decoration: underline;
  }
`;

const getStatus = (str: string) => {
  return getCapitalize(
    str
      .replace("state_", "")
      .split("_")
      .join(" ")
  );
};

export const Support = memo(() => {
  const intl = useIntl();
  const [supportTickets, setSupportTickets] = useState<SupportTicket[]>([]);
  const [createTicketDialogOpen, setCreateTicketDialogOpen] = useState(false);

  useEffect(() => {
    try {
      api("support/tickets?sort=ticket_create_date_desc", {
        method: "GET",
      }).then((data) => {
        const ticketData = normalize(data);
        const builtTicketData = build(ticketData, "supportTicket");
        if (builtTicketData) {
          setSupportTickets(builtTicketData);
        }
      });
    } catch (error) {
      toast.error("Failed to retrieve support tickets");
    }
  }, [setSupportTickets]);

  return (
    <Fragment>
      <>
        <TitleBar label="Support" />
        <Box
          width="100%"
          flexDirection="column"
          marginTop="24px"
          backgroundColor="white"
          justifyContent="center"
          borderRadius="10px"
        >
          <Box
            flexDirection="row"
            justifyContent="space-between"
            marginY="16px"
          >
            <FormTitle margin="auto 16px">Tickets</FormTitle>
            <Box
              flexDirection="row"
              alignItems="space-between"
              style={{ cursor: "pointer" }}
              onClick={() => setCreateTicketDialogOpen(true)}
              margin="auto 16px"
            >
              <Icon name={Icons.plus} width={15} height={15} />
              <StyledLinkText>Create ticket</StyledLinkText>
            </Box>
          </Box>
          <Grid
            columns={[
              {
                name: "Date",
                data: (row: SupportTicket) =>
                  _(
                    <>
                      <Box>
                        {intl.formatDate(row.ticketCreateDate, {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })}
                      </Box>

                      <Box>
                        {intl.formatTime(row.ticketCreateDate, {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </Box>
                    </>
                  ),
                width: "10%",
              },
              {
                name: "Domain(s)",
                data: (row: SupportTicket) => row.domain,
                width: "15%",
              },
              {
                name: "Ticket Number",
                data: (row: SupportTicket) => row.ticketNumber,
                width: "8%",
              },
              {
                name: "Type",
                data: (row: SupportTicket) => getCapitalize(row.ticketType),
                width: "10%",
              },
              {
                name: "Notes",
                data: (row: SupportTicket) =>
                  _(
                    <>
                      {!row.ticketHistory && (
                        <>
                          <Box
                            display="grid"
                            height="100px"
                            overflowY="scroll"
                            style={{
                              gridGap: 0,
                              width: "98%",
                              gridTemplateColumns: "16% 65% 18%",
                              fontSize: "14px",
                            }}
                          >
                            {["Date", "Note", "From"].map((h) => (
                              <Text style={{ textDecoration: "underline" }}>
                                {h}
                              </Text>
                            ))}

                            <Text marginY="8px" paddingRight="4px">
                              {intl.formatDate(row.ticketCreateDate, {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              })}
                              {"  "}
                              {intl.formatTime(row.ticketCreateDate, {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}
                            </Text>
                            <Text marginY="8px" paddingRight="4px">
                              {row.requestDescription}
                            </Text>
                            <Text marginY="8px" paddingRight="4px">
                              Customer
                            </Text>
                          </Box>
                        </>
                      )}
                      {row.ticketHistory && (
                        <>
                          <Box
                            display="grid"
                            height="100px"
                            overflowY="scroll"
                            style={{
                              gridGap: 0,
                              width: "98%",
                              gridTemplateColumns: "16% 65% 18%",
                              fontSize: "14px",
                            }}
                          >
                            {["Date", "Note", "From"].map((h) => (
                              <Text style={{ textDecoration: "underline" }}>
                                {h}
                              </Text>
                            ))}
                            {row.ticketHistory.notes.map((e) => (
                              <>
                                <Text marginY="8px" paddingRight="4px">
                                  {intl.formatDate(e.date, {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                  })}
                                  {"  "}
                                  {intl.formatTime(e.date, {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  })}
                                </Text>
                                <Text marginY="8px" paddingRight="4px">
                                  {e.body}
                                </Text>
                                <Text marginY="8px" paddingRight="4px">
                                  {e.senderType}
                                </Text>
                              </>
                            ))}
                          </Box>
                        </>
                      )}
                    </>
                  ),
                width: "30%",
              },
              {
                name: "Created by",
                data: (row: SupportTicket) => row.createdBy,
                width: "12%",
              },
              {
                name: "Status",
                data: (row: SupportTicket) => getStatus(row.ticketStatus),
                width: "10%",
              },
            ]}
            data={supportTickets}
            sort={true}
            autoWidth={false}
            style={{
              table: {
                "font-family": `Helvetic Neue, Helvetica`,
                "font-size": "16px",
              },
              th: {
                "text-wrap": "wrap",
                "font-weight": "600",
              },
              td: {
                "overflow-wrap": "break-word",
                "padding-left": "20px",
                "padding-right": "20px",
              },
            }}
          />
        </Box>
        <Modal
          isOpen={createTicketDialogOpen}
          close={() => setCreateTicketDialogOpen(false)}
        >
          <CreateTicketModal
            close={() => setCreateTicketDialogOpen(false)}
            setSupportTickets={setSupportTickets}
          />
        </Modal>
      </>
    </Fragment>
  );
});

Support.displayName = "Support";
