import React, { memo, useContext, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  Card,
  Text,
  FormInner,
  Label,
  theme,
  FormGroup,
  FormControlFeedback,
  AdaptedTextarea,
} from "@cyberalarm/ui";
import styled from "styled-components";
import build from "redux-object";
import { toast } from "react-toastify";
import { api } from "@cyberalarm/common";
import normalize from "json-api-normalizer";
import { form } from "@cyberalarm/common";
import { newTicketSchema } from "./schema";
import { DomainStateContext, domainSelectors } from "store";
import Select from "react-select";
import { ActionMeta } from "react-select";
import themeGet from "@styled-system/theme-get";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexCard = styled(Card)`
  min-width: 544px;
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 16px;
  cursor: pointer;
  font-family: ${themeGet("fonts.montserrat")};
`;

interface ModalProps {
  close: () => void;
  setSupportTickets: (supportTickets: SupportTicket[]) => void;
}

interface CreateTicketProps {
  domain: string;
  requestDescription: string;
}

interface ErrorType {
  code: string;
}

const validator = form.validateForm({ schema: newTicketSchema });

const Modal = ({ close, setSupportTickets }: ModalProps) => {
  const state = useContext(DomainStateContext);
  const domainList = domainSelectors.getDomainList(state);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const onChangeDomains = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    const values = newValue as {
      value: string;
      label: string;
    }[];
    setSelectedDomains(values.map((v) => v.value));
  };
  const [domainOptions, setDomainOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const onSubmit = async (values: CreateTicketProps) => {
    try {
      await api("support/create_ticket", {
        method: "POST",
        body: {
          domain:
            selectedDomains.length > 0 ? selectedDomains.join(", ") : null,
          request_description: values.requestDescription,
          type: "general",
        },
      });
      const data = await api("support/tickets", {
        method: "GET",
      });
      const normalizedData = normalize(data);
      //Team users refresh
      const builtTicketata = build(normalizedData, "supportTicket");
      setSupportTickets(builtTicketata);
      toast.success("Ticket created successfully");
      close();
    } catch (error) {
      toast.error("Unexpected error! Contact System Administrator.");
    }
  };

  useEffect(() => {
    const tempDomains: { value: string; label: string }[] = [];
    domainList.result.forEach((id: string) => {
      const domain: BuiltDomain = build(domainList.entities, "domain", id);
      tempDomains.push({ value: domain.name, label: domain.name });
    });
    setDomainOptions(tempDomains);
  }, [domainList, setDomainOptions]);

  return (
    <>
      <FlexCard>
        <Box marginX={6} marginY={6} flex={1} minHeight="68px">
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text fontSize="24px" fontFamily="title">
                Create Ticket
              </Text>
            </Label>
          </Box>

          <Form onSubmit={onSubmit} validate={validator}>
            {({ values, handleSubmit, submitting, valid }) => {
              return (
                <FormInner onSubmit={handleSubmit}>
                  <Box mt={4}>
                    <FlexBox mt={3}>
                      <FormGroup>
                        <Label>Select Domains</Label>
                        <StyledSelect
                          id="domain"
                          isMulti
                          options={domainOptions}
                          onChange={onChangeDomains}
                        />
                        <FormControlFeedback name="domain" />
                      </FormGroup>
                    </FlexBox>
                    <FlexBox mt={3}>
                      <FormGroup>
                        <Label>Description *</Label>
                        <Field
                          component={AdaptedTextarea}
                          id="requestDescription"
                          name="requestDescription"
                          label="Description"
                        />
                        <FormControlFeedback name="requestDescription" />
                      </FormGroup>
                    </FlexBox>

                    <Box alignItems="center">
                      <FormButton mt="48px" type="submit" variant="primary">
                        Submit
                      </FormButton>
                    </Box>
                  </Box>
                </FormInner>
              );
            }}
          </Form>
        </Box>
      </FlexCard>
    </>
  );
};

export const CreateTicketModal = memo(Modal);

CreateTicketModal.displayName = "CreateTicket";
